@font-face {
  src: url('../polices/fontastique.ttf');
  font-family: 'fontastique';
}

@font-face {
  src: url('../polices/tabitha.ttf');
  font-family: 'tabitha';
  font-size: 20px;
}

/* #root {
  background: center / contain no-repeat url("../images/cassée.jpg");
  background-size: cover;
} */

h1 {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  color: #FFFFFF;
}

h4 {
  color: #000000;
}

.Top {
  background: #A6A8AB;
}

.Header {
  background: #A6A8AB;
  color: #FFFFFF;
  padding: 0;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.Brand {
  background: #A6A8AB;
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.Logo {
  width: 20%;
  border-radius: 100px;
}

.Navbar {
  overflow: hidden;
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  width: 70%;
}

.Accueil {
  font-family: 'tabitha', Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  background: #A6A8AB;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.Col {
  display: flex;
  flex-direction: row;
}

.Card {
  border-radius: 10px;
  padding: 0 5px;
}

.CardBody {
  padding: 0;
}

.CardTitle {
  margin-top: 1.25rem;
  text-align: center;
  color: #019446;
}

.CardBtn {
  width: calc(100% - 2.5rem);
  margin: 1.25rem;
  display: flex;
  justify-content: center;
  background: #A6A8AB;
  border-radius: 100px;
}

.CardImg {
  padding: 0;
  border-radius: 10px;
  height: 200px;
  width: 100%;
}

.Tarifs {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  padding: 20px;
  font-size: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 70%;
  background: #A6A8AB;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.DescriptionTarif {
  height: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.TableTarif {
  border: 1px solid #019446;
  border-radius: 40px;
}

.table {
  text-align: center;
  border-collapse: unset;
}

.table td {
  border-top: none;
}

.More {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 30px;
}

.Form {
  margin-left: 10px;
  margin-right: 10px;
}

.Send {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.Button {
  background: #019446;
}

.Realisations {
  font-family: 'tabitha', Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  background: #A6A8AB;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.RealList {
  font-family: 'tabitha', Arial, Helvetica, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.Real {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  background: #A6A8AB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-gallery-icon {
  margin: 10px 10px 0 10px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: auto;
  height: calc(100vh - 150px);
}

.Societe {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  padding: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  background: #A6A8AB;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.Description {
  font-family: 'tabitha', Arial, Helvetica, sans-serif;
  font-size: 20px;
  display: inline;
  margin: auto 0;
  height: auto;
  text-align: center;
}

.Presentation {
  display: flex;
  flex-direction: row;
  font-size: 20px;
}

.Bold {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.Groupe {
  display: block;
  text-align: center;
}

.GroupeImg {
  max-width: 450px;
  max-height: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Proud {
  margin-top: 20px;
  text-align: center;
  color: #FFFFFF;
}

.Carousel {
  width: 90%;
  background: #FFFFFF;
  border-radius: 50px;
  padding: 10px;
}

.Company {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 200px;
}

.Contact {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0 0 30px 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  background: #A6A8AB;
  justify-content: center;
  z-index: 1;
}

.Informations {
  font-family: 'fontastique', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Infos {
  font-family: 'tabitha', Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-left: 25px;
  margin-right: 25px;
}

.Map {
  display: flex;
  justify-content: center;
  margin: 100px;
  margin-top: 30px;
  position: relative;
}

.leaflet-container {
  width: 75%;
  height: 75vh;
}

.navbar-dark .navbar-nav .nav-link {
  color: #FFFFFF;
}

.nav-link {
  color: #000000;
}

.fa-instagram {
  color: #FFFFFF;
  fill-opacity: 1;
  margin: 0 5px;
}
.fa-facebook-f {
  color: #FFFFFF;
  fill-opacity: 1;
  margin: 0 5px; 
}

.fa-phone {
  color: #019446;
  fill-opacity: 1;
}

.fa-envelope {
  color: #019446;
  fill-opacity: 1;
}

.fa-clock {
  color: #019446;
  fill-opacity: 1;
}

.fa-home {
  color: #019446;
  fill-opacity: 1;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
  background: #019446;
  border-radius: 10px 10px 0 0;
}

a:hover {
  color: #019446;
}

.Social {
  color: #019446;
}

.Separation {
  background: #019446;
  height: 5px;
}

@media (max-width: 1100px) {
  .Navbar {
    top: 0;
    margin: 0;
    width: 100%;
  }

  .Accueil {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .Card {
    margin-bottom: 20px;
  }

  .Tarifs {
    width: 100%;
    margin-bottom: 20px;
  }

  .DescriptionTarif {
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .Realisations {
    width: 100%;
    margin-bottom: 20px;
  }

  .Societe {
    width: 100%;
    margin-bottom: 20px;
  }

  .Description {
    width: 100%;
  }

  .Groupe {
    padding: 10px;
    width: 100%;
  }

  .GroupeImg {
    width: 70%;
  }

  .Company {
    background: #FFFFFF;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    padding: 5px
  }

  .Contact {
    width: 100%;
    margin-bottom: 20px;
  }

  .Informations {
    display: flex;
    flex-direction: column;
  }

  .Map {
    margin: 0;
    margin-top: 20px;
    z-index: 1;
  }
}